<template>
  <!-- <b-container class="container-content card-container translateYg text-center">
  <b-spinner  variant="primary" label="Spinning" class="text-center "></b-spinner>
  </b-container> -->
  <div></div>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import { isProduction } from '../../utils'
import { isUATEnv } from '../../helpers/network'
import ApiClient from '../../services'

const BookingStore = createNamespacedHelpers('booking')
const AuthStore = createNamespacedHelpers('auth')

@Component({
  methods: {
    ...BookingStore.mapMutations([
      'setBookingReview',
      'setPaymentBody',
      'setPaymentResult',
      'resetState',
    ]),
    ...BookingStore.mapActions(['processBooking', 'updateBooking']),
    ...AuthStore.mapActions(['refreshToken']),
  },
  computed: {
    ...BookingStore.mapState(['bookingReview', 'paymentBody', 'paymentResult']),
  },
})
export default class PaymentResult extends Vue {
  isProcessing = false
  isRetry = false

  get loadingVariant() {
    return this.bookingReview.type === 'one-time' ? `success` : `warning`
  }

  async mounted() {
    this.$store.commit('setIsLoading', true)
    this.resetState()

    const bookingDetailStr = window.localStorage.getItem('booking_detail')
    const paymentBodyStr = window.localStorage.getItem('payment_body')
    console.log(JSON.parse(bookingDetailStr), JSON.parse(paymentBodyStr))
    if (!bookingDetailStr) {
      this.$router.push({ name: 'Home' })
    }
    const bookingDetailJson = JSON.parse(bookingDetailStr)
    const paymentBodyJson = JSON.parse(paymentBodyStr)

    this.setBookingReview(bookingDetailJson)
    this.setPaymentBody(paymentBodyJson)
    // console.log('eeee:', this.bookingReview.isUpdateBooking)
    // Case Update Booking
    if (this.bookingReview.isUpdateBooking) {
      await this.doUpdateBooking()
    } else {
      await this.doProcessBooking(false)
    }
  }

  async destroyed() {
    this.$store.commit('setIsLoading', false)
  }

  async doUpdateBooking() {
    this.isProcessing = true
    try {
      console.log('========= doUpdateBooking')
      const result = await this.updateBooking()
      if (result) {
        this.setPaymentResult({
          status: result.status,
          type: this.bookingReview.type,
          isUpdateBooking: this.bookingReview.isUpdateBooking,
        })
      }
    } catch (e) {
      console.error(e)
      const { data, status } = e.response
      if (status === 401 && !this.isRetry) {
        this.isRetry = true
        await this.refreshToken()
        await this.doUpdateBooking()
        return
      }
      this.setPaymentResult({
        errorMessage: data.error,
        status,
        type: this.bookingReview.type,
        isUpdateBooking: this.bookingReview.isUpdateBooking,
      })
    } finally {
      this.paymentResult.status === 200
        ? (localStorage.isPaid = true)
        : (localStorage.isPaid = false)
      localStorage.paymentResult = JSON.stringify(this.paymentResult)
      this.$store.commit('booking/resetCurrentBookingOneTime')
      this.$store.commit('booking/resetCurrentBookingMultiPackage')
      this.$store.commit('booking/resetBookingReview')
      this.$store.commit('booking/resetBookingMultiPackage')
      // localStorage.removeItem('useCoupon')
      // localStorage.removeItem('bodyPaySolution')
      // localStorage.removeItem('isRedirect')
      this.$router.push({ name: 'BookingConfirm' })
      // localStorage.removeItem('isPaid')
    }
  }

  async doProcessBooking(isApproved = true) {
    console.log('this.bookingReview', localStorage.confirmPayment)
    const confirm_booking = await ApiClient.confirmBooking(JSON.parse(localStorage.confirmPayment))
    localStorage.confirmPayment = null
    // if( confirm_booking)
    console.log('confirm booking', confirm_booking)
    console.log('========== doProcessBooking')
    this.isProcessing = true
    try {
      // if (typeof this.bookingReview.coupon_body === 'object' && this.bookingReview.coupon_body) {
      //   this.bookingReview.coupon_body.booking_id = this.bookingReview.id
      //   this.bookingReview.coupon_body.used = true
      //   await ApiClient.applyCoupon(this.bookingReview.coupon_body)
      // }
      // confirm_booking = await ApiClient.confirmBooking(JSON.parse(localStorage.confirmPayment))
      // // localStorage.removeItem('confirmPayment')
      // this.isProcessing = true
      const result = await this.processBooking(isApproved)
      // console.log(result)
      if (result) {
        // if (isProduction()) {
        //   this.$gtag('event', 'conversion', {
        //     send_to: 'AW-685115829/6OegCM_e6eIBELWT2MYC',
        //     transaction_id: this.paymentBody.refno || '',
        //   })
        //   fbq('init', '202147018091606')
        //   fbq('track', 'PageView')
        // }
        this.setPaymentResult({
          status: result.status,
          type: this.bookingReview.type,
          isUpdateBooking: this.bookingReview.isUpdateBooking,
        })
      }
    } catch (e) {
      console.log(e.response)
      const { data, status } = e.response
      console.log('eeee', e)
      if (status === 401 && !this.isRetry) {
        this.isRetry = true
        await this.refreshToken()
        await this.doProcessBooking()
        return
      }

      this.setPaymentResult({
        errorMessage: data.error,
        status,
        type: this.bookingReview.type,
        isUpdateBooking: this.bookingReview.isUpdateBooking,
      })
    } finally {
      console.log('this.paymentResult', this.paymentResult)
      this.paymentResult.status === 200
        ? (localStorage.isPaid = true)
        : (localStorage.isPaid = false)
      // console.log('finally')
      // if (isProduction() && this.paymentResult.status === 200) {
      //   this.$gtag.event('conversion', {
      //     send_to: 'AW-685115829/6OegCM_e6eIBELWT2MYC',
      //     transaction_id: this.paymentBody.refno || '',
      //   })
      //   fbq('init', '202147018091606')
      //   fbq('track', 'PageView')
      // }
      localStorage.paymentResult = JSON.stringify(confirm_booking)
      this.$router.push({ name: 'BookingConfirm' })
    }
  }
}
</script>

<style lang="css">
.card-container {
  min-height: auto;
}
</style>
